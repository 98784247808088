// font-loader-config.js
import WebFont from 'webfontloader';

WebFont.load({
  google: {
    families: ['Rokkitt', 'IBM Plex Sans'],
  },
//   custom: {
//     families: ['CustomFont'],
//     urls: ['/path-to-your-custom-font.css'],
//   },
});
